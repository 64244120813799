import TopNavThree from "@/components/Header/Sloth/TopNavThree";
import Menu from "@/components/Header/Menu/MenuSloth";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import Footer from "@/components/Footer/Footer";

const WholeSale = () => {
  return (
    <>
      <TopNavThree props="style-three bg-white" />
      <div id="header" className="relative w-full">
        <Menu />
        <Breadcrumb heading="Become a distributor" subHeading="Wholesale" />
      </div>
      <div className="contact-us md:py-20 py-10">
        <div className="container">
          <div className="flex justify-between max-lg:flex-col gap-y-10">
            <div className="left lg:w-2/3 lg:pr-4">
              <div className="heading3">Drop Us A Line</div>
              <div className="body1 text-secondary2 mt-3">
                Use the form below to get in touch with our team
              </div>
              <form
                className="md:mt-6 mt-4"
                name="wholesale"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                //@ts-ignore
                netlify
              >
                <input type="hidden" name="form-name" value="wholesale" />

                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 gap-y-5">
                  <div className="name ">
                    <input
                      className="border-line px-4 py-3 w-full rounded-lg"
                      id="name"
                      type="text"
                      placeholder="Your Name *"
                      required
                      name="name"
                    />
                  </div>
                  <div className="name ">
                    <input
                      className="border-line px-4 py-3 w-full rounded-lg"
                      id="phoneNumber"
                      type="text"
                      placeholder="Your Phone Number *"
                      required
                      name="phoneNumber"
                    />
                  </div>
                  <div className="email">
                    <input
                      className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                      id="email"
                      type="email"
                      placeholder="Your Email *"
                      required
                      name="email"
                    />
                  </div>
                  <div className="name ">
                    <input
                      className="border-line px-4 py-3 w-full rounded-lg"
                      id="subject"
                      type="text"
                      placeholder="Subject *"
                      required
                      name="subject"
                    />
                  </div>
                  <div className="message sm:col-span-2">
                    <textarea
                      className="border-line px-4 pt-3 pb-3 w-full rounded-lg"
                      id="message"
                      rows={3}
                      placeholder="Your Message *"
                      required
                      name="message"
                    />
                  </div>
                </div>
                <div className="block-button md:mt-6 mt-4">
                  <button className="button-main">Send message</button>
                </div>
              </form>
            </div>
            <div className="right lg:w-1/4 lg:pl-4"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WholeSale;
